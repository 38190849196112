import GlobalLayout from "components/page/GlobalLayout";
import TeamsPageTemplate from "components/teams/TeamsPageTemplate";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function SecurityTeamsPage() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "SECURITY",
        title: "Protect Your Spaces with Smart, Seamless Technology ",
        description:
          "Make faster, more informed decisions with lightning-quick performance on a cloud platform that unifies security cameras, access control, alarms, sensors, and integrations.",
        image: data.headerImage,
        button1: { text: "Request Demo", path: null },
      },
      logos: [
        {
          img: data.logo1,
          width: "130px",
          alt: "Hurley",
        },
        {
          img: data.logo2,
          width: "177px",
          alt: "Aramark",
        },
        {
          img: data.logo6,
          width: "137px",
          alt: "Clark Construction",
        },
        {
          img: data.logo4,
          width: "186px ",
          alt: "Los Angeles Film School",
        },
        {
          img: data.logo5,
          width: "149px",
          alt: "Goodwill",
        },
        {
          img: data.logo3,
          width: "115px",
          alt: "Stryker",
        },
      ],
      benefitRows: [
        {
          preTitle: "BETTER LIVE MONITORING",
          title: "Cover Blind Spots and Get Visibility from Anywhere",
          description:
            "Get the complete picture of activity across all locations—native remote access lets you view footage on any device from anywhere in the world. With crystal-clear video and ultra-low latency streams, it's easy to quickly and accurately assess what’s occurring across your spaces. ",
          specs: [
            { title: "Unlimited", description: "locations and users" },
            {
              title: "99.9%",
              description: "uptime for unlimited devices and locations",
            },
          ],
          image: data.benefitImage1,
        },
        {
          preTitle: "PROACTIVE MONITORING",
          title: "Stay Ahead of Threats with Real-Time AI Alerts",
          description:
            "Detect potential threats and receive synchronized security footage for persons-of-interest, unauthorized vehicles, after-hours activity, and more. Accelerate emergency response and quickly investigate potential safety risks before they escalate to minimize risk and improve the security of your people, places, and assets. ",
          specs: [
            { title: "18x", description: "faster incident resolution" },
            {
              title: "24/7",
              description: "automatic monitoring",
            },
          ],
          image: data.benefitImage2,
        },
        {
          preTitle: "FASTER INVESTIGATIONS ",
          title: "Find Footage in Seconds ",
          description:
            "Say goodbye to manual scrubbing and speed up investigations with smart search. With multi-camera investigations and automatically indexed events, it’s easy to jump to relevant video segments and quickly find human movement, access events, and additional activity types. ",
          specs: [
            { title: "90%", description: "faster incident investigations" },
            {
              title: "Zero",
              description: "missing footage",
            },
          ],
          image: data.benefitImage3,
        },
      ],
      infoSlider: {
        title: "Feature-Rich Security Platfom for Security Teams ",
        slides: [
          {
            tabTitle: "Video Walls",
            preTitle: "BETTER LIVE MONITORING ",
            title: "Video Walls",
            description:
              "Enhance live monitoring and see the whole picture at a glance with custom video walls. View livestreams from multiple cameras on a single display, securely share walls, and create a control room experience from anywhere. ",
            image: data.infoSlide1,
          },
          {
            tabTitle: "Real-Time Alerts",
            preTitle: "PROACTIVE MONITORING",
            title: "Real-Time Alerts",
            description:
              "Proactively identify and respond to potential threats with real-time alerts based on AI analytics. Create custom alerts and receive notifications on any device via email, SMS, or mobile app. ",
            image: data.infoSlide2,
          },
          {
            tabTitle: "Smart Search",
            preTitle: "FASTER INVESTIGATIONS",
            title: "Smart Search",
            description:
              "Accelerate investigations by pinpointing footage based on key details. Search using AI analytics and find activity based on human movement, facial recognition, license plate recognition, audio, color search, and more. ",
            image: data.infoSlide3,
          },
          {
            tabTitle: "Easy Sharing",
            preTitle: "COMMUNICATE & COLLABORATE",
            title: "Easy Sharing",
            description:
              "Collaboration is easy, fast, and flexible. Securely share video clips, livestreams, and historical streams with anyone—send footage via text, email, and URL and view on any device with no login required. ",
            image: data.infoSlide4,
          },
          {
            tabTitle: "Mobile App",
            preTitle: "ON-THE-GO MONITORING",
            title: "Mobile App",
            description:
              "Monitor security feeds without being glued to a desk. Perfect for patrolling—the Rhombus Mobile App makes it easy to view security footage and manage cameras right on your phone. There are no user or device limits, so you can equip your entire team with the visibility they need.  ",
            image: data.infoSlide5,
          },
        ],
      },
      customerReviews: [
        {
          image: data.review1,
          author: {
            avatar: data.avatar1,
            name: "Rob Zimprich",
            role: "CFO of UACDC",
          },
        },

        {
          image: data.review2,
          author: {
            avatar: data.avatar2,
            name: "RC Miles",
            role: "Global Director of Safety and Security at AHF",
          },
        },
        {
          image: data.review3,
          author: {
            avatar: data.avatar3,
            name: "Erik Tank",
            role:
              "Systems and Security Architect of East Moline School District 37",
          },
        },
        {
          image: data.review4,
          author: {
            avatar: data.avatar4,
            name: "John Eaton",
            role: "vCIO of Kelley Connect",
          },
        },
        {
          image: data.review5,
          author: {
            avatar: data.avatar5,
            name: "Susan Bohl",
            role: "Executive Director at Oklahoma One-Call System Inc.",
          },
        },
      ],
      formSection: {
        preTitle: "REQUEST DEMO",
        title: "Interested in Rhombus?",
        description:
          "Get a demo today and see how Rhombus transforms IT departments into more nimble and scalable teams.",
        learnLines: [
          "Why a cloud-native solution is superior to on-premise video security systems",
          "The benefits of using a unified platform with smart cameras, sensors, and integrations",
          "How to improve safety and productivity with powerful AI analytics",
        ],
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Rhombus for Security Teams - Protect Your Spaces</title>
          <meta
            name="description"
            content="Reliably monitor spaces, detect potential threats in real time, and take proactive measures to safeguard buildings and the people within."
          />
        </Helmet>
        <TeamsPageTemplate data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/teams/img/smart-search-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/lafs-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefitImage1: file(
        relativePath: { eq: "components/ITPage/img/modernize-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage2: file(
        relativePath: { eq: "components/teams/img/flagged-vehicle-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage3: file(
        relativePath: { eq: "components/teams/img/safer-schools-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/teams/img/video-walls-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/alarms/updated/img/audio-deterrent-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: { eq: "components/ITPage/img/stream-line-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/teams/img/shared-clip-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar1: file(
        relativePath: { eq: "components/common/img/headshots/rob-z.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar2: file(
        relativePath: { eq: "components/common/img/headshots/rc-miles.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar3: file(relativePath: { eq: "components/ITPage/img/erik.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar4: file(
        relativePath: { eq: "components/common/img/headshots/john-eaton.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar5: file(
        relativePath: { eq: "components/common/img/headshots/susan-b.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      review1: file(
        relativePath: { eq: "components/common/img/reviews/rob-z-review.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review2: file(
        relativePath: {
          eq: "components/common/img/reviews/rc-miles-review.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review3: file(relativePath: { eq: "components/common/img/review3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review4: file(
        relativePath: { eq: "components/common/img/reviews/john-e-review.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review5: file(
        relativePath: { eq: "components/common/img/reviews/susan-b-review.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
